import React, { useEffect, useCallback } from "react"
import { graphql } from "gatsby"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { useData } from "../utils/DataProvider"
import { moveLines, coverReveal } from "../animations/Animate"
import LayoutInner from "../components/LayoutInner"
import Hero from "../components/Hero"
import SEO from "../components/Seo"
import ImageText from "../components/ImageText"

gsap.registerPlugin(ScrollTrigger)

const TeamPage = ({ data, location }) => {
  const [state, setState] = useData()
  const teamData = data.teamQuery

  useEffect(() => {
    setState({ ...state, color: teamData.meta.color })
  }, [setState, teamData])

  const teamAnimation = useCallback(() => {
    // S1
    coverReveal('100%')

    moveLines()

    // ScrollTrigger.batch('.jsTeamEl', {
    //   onEnter: batch => gsap.from(batch, {
    //     y: 150,
    //     autoAlpha: 0,
    //     stagger: 0.3,
    //     duration: 1,
    //     ease: "power3.easeOut",
    //   })
    // });
  })

  useEffect(() => {
    teamAnimation()
  }, [])

  return (
    <LayoutInner location={location}>
      <SEO title={teamData.meta.title} />
      <Hero data={teamData.heroQuery} />
      <ImageText
        data={teamData.sectionsQuery.section1}
        order={"image,content"}
      />
    </LayoutInner>
  )
}

export default TeamPage

export const query = graphql`
  query Team {
    teamQuery: teamPageJson {
      meta {
        title
        shareImage
        keywords
        color
      }
      heroQuery: hero {
        title
        stripes
      }
      sectionsQuery: sections {
        section1 {
          content
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`
